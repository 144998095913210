

$white:                         #fff !default;
$dark-white:                    #fbfbfb !default;
$gray-100:                      #f8f9fa !default;
$gray-200:                      #e9ecef !default;
$gray-300:                      #dee2e6 !default;
$gray-400:                      #C1C1C1 !default;
$gray-500:                      #adb5bd !default;
$gray-600:                      #6c757d !default;
$gray-700:                      #495057 !default;
$gray-800:                      #343a40 !default;
$gray-900:                      #212529 !default;
$black:                         #000 !default;
$red:                           #FF2D00 !default;
$blue:                          #3700ff !default;
// $primary-color:                 #262c4c !default;
$primary-color:                 #25b35f !default;
$green:                         #00c050 !default;
$dsr-primary-color:             #262c4c !default;
$dsr2-primary-color:            #D5A09C !default;

$font-khmer-os-battambang:      'Khmer OS Battambang';
$font-khmer-os-muol:            'Khmer OS Muol';
$font-khmer-os-muol-light:      'Khmer OS Muol Light';
