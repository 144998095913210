.basic-input {
    border: 1px solid $primary-color !important;
    border-radius: 0 !important;

    &:hover {
        box-shadow: 0 0 5px $primary-color !important;
    }

    &:focus {
        box-shadow: 0 0 5px $primary-color !important;
    }
}

.basic-date-picker {
    border: 1px solid $primary-color !important;
    border-radius: 0 !important;

    &:hover {
        box-shadow: 0 0 5px $primary-color !important;
    }

    &:focus {
        box-shadow: 0 0 5px $primary-color !important;
    }
}

.ant-picker-dropdown {
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background-color: $primary-color !important;
    }

    .ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: $primary-color !important;
    }

    .ant-picker-week-panel-row-selected td {
        background-color: $primary-color !important;
    }
}


.basic-select {
    border: 1px solid $primary-color !important;

    &:hover {
        box-shadow: 0 0 5px $primary-color !important;
    }

    &:focus {
        box-shadow: 0 0 5px $primary-color !important;
    }

    .ant-select-selector {
        border: none !important;
    }
}

.ant-select-focused {
    .ant-select-selector {
        border: none !important;
        box-shadow: 0 0 5px $primary-color !important;
    }
}

.ant-input-number-affix-wrapper {
    border: 1px solid $primary-color !important;

    &:hover {
        box-shadow: 0 0 5px $primary-color !important;
    }

    &:focus {
        box-shadow: 0 0 5px $primary-color !important;
    }
}

.basic-auto-complete {
    // .ant-select-auto-complete {
        input, button {
            border: 1px solid $primary-color !important;
        }
    
        button {
            .anticon-search {
                color: $primary-color !important;
            } 
        }

        &:hover {
            input, button {
                border: 1px solid $primary-color !important;
            }
        
            button {
                .anticon-search {
                    color: $primary-color !important;
                } 
            }
        }
    // }
}


// Disabled Style
.ant-select-disabled .ant-select-arrow {
  cursor: auto !important;
  color: $white !important;
}

.ant-input-disabled {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-input-number-disabled .ant-input-number-input {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-picker-input > input[disabled] {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: $black !important;
    background-color: $white !important;
    cursor: auto !important;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector input {
    cursor: auto !important;
}

.ant-input-affix-wrapper-disabled {
    background-color: $white !important;
    color: $black !important;
    cursor: auto !important;
    border-color: $primary-color !important;
}

.remove-red-star {
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none !important;
    }
}