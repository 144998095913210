// Font
.font-km-os-battambang {
    font-family: $font-khmer-os-battambang !important;
}

.font-km-os-muol {
    font-family: $font-khmer-os-muol !important;
}

.font-km-os-muol-light {
    font-family: $font-khmer-os-muol-light !important;
}

.to-upper-case {
    text-transform: uppercase !important;
}

// Border
// Border Color
.border-primary {
    border: 1px solid $primary-color
}

.border-gray {
    border: 1px solid $gray-100
}

// Border None
.border-left-none {
    border-left: none !important;
}

.border-right-none {
    border-right: none !important;
}

// Background
.bg-primary {
    background-color: $primary-color !important;
}

.bg-white {
    background-color: $white !important;
}

// Button
.btn-primary {
    background-color: $primary-color !important;
    color: $white !important;
}

// Text Color
.c-black {
    color: $black !important;
}

.c-primary {
    color: $primary-color !important;
}

.c-red {
    color: $red !important;
}

.c-green {
    color: $green !important;
}

.c-tooltip {
    color: rgba(0, 0, 0, 0.45) !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.fw-bold {
    font-weight: bold !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-help {
    cursor: help !important;
}

.cursor-pointer-hover {
    cursor: pointer !important;
    &:hover {
        color: $primary-color !important;
    }
}

.display-none {
    display: none !important;
}

// HR
.hr-primary {
    border-top: 2px solid $primary-color !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
}

.ant-table-content {
    &::-webkit-scrollbar {
        color: $black;
        width: 10px;
        height: 10px;
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: $primary-color !important;
}

.ant-switch-checked {
    background-color: $primary-color !important;
}

.ant-upload-drag {
    &:hover {
        border: 1px dashed $primary-color !important;
        // box-shadow: 0 0 10px $primary-color;
    }
}

.ant-pagination-item-link {
    &:hover {
        color: $primary-color !important;
        border: 1px solid $primary-color !important;
        box-shadow: 0 0 5px $primary-color !important;
    }
}
  
.ant-pagination-disabled {
    .ant-pagination-item-link {
        &:hover {
            box-shadow: none !important;
        }
    }
}
  
.ant-pagination-item {
    &:hover {
        border: 1px solid $primary-color;
        box-shadow: 0 0 5px $primary-color;
        a {
            color: $primary-color;
        }
    }
}
  
.ant-pagination-item-active {
    border: 1px solid $primary-color !important;
    a {
        color: $primary-color !important;
    }
    &:hover {
        border: 1px solid $primary-color;
        box-shadow: 0 0 5px $primary-color;
        a {
            color: $primary-color;
        }
    }
    &:focus {
        border: 1px solid $primary-color;
        a {
            color: $primary-color;
        }
    }
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary-color !important;
}

.ant-tabs-tab {
    &:hover {
        color: $primary-color !important;
    }
}

// Spin
.anticon-spin {
    color: $primary-color !important;
}

.ant-spin-text {
    color: $primary-color !important;
}

// Logo
.top-logo {
    img {
        width: 60% !important;
        height: auto;
    }
}

.vertical-top {
    vertical-align: text-top !important;
}

.c-dsr2-primary-color {
    color: $dsr2-primary-color !important;
}